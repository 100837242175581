/* Base styles */
body {
  font-size: 16px;
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  body {
    font-size: 18px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  body {
    font-size: 20px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  body {
    font-size: 22px;
  }
}

/* Small devices (mobile phones, less than 768px) */
@media (max-width: 767px) {
  body {
    font-size: 14px;
  }

  .table {
    font-size: 12px;
  }

  .navbar {
    font-size: 12px;
  }

  .card {
    font-size: 12px;
  }
}

.tableCell {
  padding: 4px 8px;
  font-size: 0.9em;
  text-align: center;
}

.tableFlag {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.tableFlag img {
  width: 24px;
  height: auto;
}

.tableCellCountry {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tableCellCountry span {
  margin-left: 4px;
}

/* App.css */
.logo {
  width: 80px;
  height: auto;
  margin-bottom: 16px;
}

.welcome-text {
  font-weight: bold;
  color: #333;
}

.subtitle-text {
  color: #666;
}

.table-container {
  overflow-x: auto;
}

.rotate-header {
  display: inline-block;
  transform: rotate(-45deg);
  transform-origin: left bottom;
  text-align: left;
  white-space: nowrap;
}

.fixed-col {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  left: 0;
  background-color: #fff;
  z-index: 2;
}
